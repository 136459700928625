<template lang="">
  <components
    :is="lazy ? 'BImgLazy' : 'BImg'"
    v-b-tooltip.hover.html.v-primary.top.window="!hideTooltip ? titleTooltip : ''"
    :style="`${!Number(customWidth) ? `max-height: ${customHeight}px` : '' } ${customStyle}`"
    :width="Number(customWidth)"
    :class="`px-0 ${customClass}`"
    :src="src"
    :rounded="rounded"
    :right="right"
    :left="left"
    offset
    :alt="alt || airline"
  />
</template>
<script>
import { BImgLazy, VBTooltip, BImg } from 'bootstrap-vue'
import { ref, watchEffect, computed } from '@vue/composition-api'

import store from '@/store'
import { toDataUrl } from '@/@core/utils/utils'

export default {
  components: { BImgLazy, BImg },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    airline: {
      type: String,
      default: '',
      require: true,
    },
    alt: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 0,
    },
    hideTooltip: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      validator: val => ['xs', 'sm', 'md', 'lg'].includes(val),
      default: 'sm',
    },
    longLogo: {
      type: Boolean,
      default: false,
    },
    customWidth: {
      type: [Number, String],
      default: 0,
    },
    customStyle: {
      type: String,
      default: '',
    },
    rounded: {
      type: [Boolean, String],
      default: false,
    },
    right: {
      type: [Boolean, String],
      default: false,
    },
    left: {
      type: [Boolean, String],
      default: false,
    },
    lazy: {
      type: [Boolean, String],
      default: true,
    },
    blob: {
      type: [Boolean, String],
      default: false,
    },
  },
  setup(props, { root }) {
    const sizeOptions = {
      xs: 20,
      sm: 40,
      md: 60,
      lg: 100,
    }
    const sizeOptionsMobileView = {
      xs: 15,
      sm: 25,
      md: 35,
      lg: 45,
    }

    function getAirlineNameByCode(code) {
      return store.getters['globalConfig/getAirlineNameByCode'](code)
    }

    const airline = computed(() => ['VN1A_MT', 'VN1A', '1S', '1S_CTRL'].includes(props.airline.trim())
      ? 'VN'
      : ['NDC', 'GDS'].includes(props.airline) ? '1G' : props.airline.trim()) // FIXME NDC GDS Có cả 1A
    const airlinesCanLongLogo = ['VU', 'QH', 'VN', 'VJ', 'BL']
    const url = process.env.VUE_APP_LOGO_AIRLINE_URL
    const src = ref('')

    watchEffect(async () => {
      const link = `${url}/${airline.value}${(airlinesCanLongLogo.includes(airline.value) && props.longLogo) ? '_long' : ''}.png`
      if (props.blob) {
        const urlBase64 = await toDataUrl(link)
        src.value = urlBase64
      } else {
        src.value = link
      }
    })

    const titleTooltip = ref(getAirlineNameByCode(airline.value) || airline.value)
    const customHeight = ref(props.height || (root.isMobileView ? (sizeOptionsMobileView[props.size] || 25) : (sizeOptions[props.size] || 40)))
    return {
      src,
      titleTooltip,
      sizeOptions,
      customHeight,
    }
  },
}
</script>
<style lang="">

</style>
